<template>
  <v-card elevation="4" class="mb-8">
    <v-card-title class="pb-6">
      <h6>
        {{ $t('heading.hosting.summary.instancesCard.title') }}
        <span class="font-weight-regular">{{
          !loading ? `(${totalInstances})` : ""
        }}</span>
      </h6>
    </v-card-title>

    <v-divider />

    <v-card-text class="d-flex flex-column pt-2 px-0">
      <template v-if="!loading">
        <template v-if="instances.length">
          <router-link
            :to="`/instances/${item.id}`"
            class="d-flex py-2 instance px-6"
            v-for="(item, index) in instances"
            :key="item.id + '-' + index"
          >
            <router-link :to="`/instances/${item.id}`">
              <instance-avatar
                :instance="item"
                :size="40"
                :ssl="true"
              />
            </router-link>

            <div class="d-flex flex-column ml-4 instance__title">
              <h6 class="d-flex align-center">
                {{ item.title }}
                <v-tooltip
                  v-if="item.service_status === 'suspended'"
                  transition="custom-tooltip"
                  open-delay="150"
                  bottom
                  z-index="99"
                  offset-overflow
                  nudge-bottom="12px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div class="suspend-chip ml-2" v-on="on" v-bind="attrs">
                      <v-icon color="sand darken-2" size="12" class="mr-1">
                        $suspended
                      </v-icon>
                      {{ $t('message.status.suspended') }}
                    </div>
                  </template>
                  <div class="d-flex align-center">
                    <v-icon color="sand darken-2" size="16">$suspended</v-icon>
                    <span class="sand--text text--darken-2 ml-1">
                      {{ $t('tooltip.hosting.service.suspended') }}
                    </span>
                  </div>
                </v-tooltip>
              </h6>

              <a
                class="instance__link"
                @click="$event.stopPropagation()"
                :href="item.url"
                >{{ item.url }}</a
              >
            </div>
            <div class="d-flex ml-auto">
              <shares-label :instance="item" class="mr-2" />
              <status-indicator :sameSize="true" :instance="item" />
            </div>
          </router-link>
        </template>
        <div class="no-data" v-else>
          <svg
            width="64"
            height="64"
            viewBox="0 0 64 64"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_6309_21177)">
              <path
                d="M51.8729 18.1802H18.1612C17.1163 18.1802 16.2693 19.0272 16.2693 20.0721V47.6937C16.2693 48.7386 17.1163 49.5856 18.1612 49.5856H51.8729C52.9178 49.5856 53.7648 48.7386 53.7648 47.6937V20.0721C53.7648 19.0272 52.9178 18.1802 51.8729 18.1802Z"
                fill="#EEEEF4"
                stroke="#797997"
                stroke-width="1.34838"
                stroke-miterlimit="10"
              />
              <path
                d="M48.035 22.5765H14.3233C13.2784 22.5765 12.4314 23.4236 12.4314 24.4684V52.0901C12.4314 53.1349 13.2784 53.9819 14.3233 53.9819H48.035C49.0799 53.9819 49.9269 53.1349 49.9269 52.0901V24.4684C49.9269 23.4236 49.0799 22.5765 48.035 22.5765Z"
                fill="white"
                stroke="#B0B0D3"
                stroke-width="1.34838"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M25.8369 37.5677C25.8369 39.7659 27.1162 41.6578 28.972 42.5587L26.3234 35.3154C26.0171 36.0001 25.8369 36.7749 25.8369 37.5677Z"
                fill="#797997"
              />
              <path
                d="M35.1343 37.2973C35.1343 36.6127 34.882 36.1442 34.6838 35.7658C34.3955 35.3154 34.1433 34.919 34.1433 34.4685C34.1433 33.964 34.5217 33.4775 35.0802 33.4775C35.0983 33.4775 35.1343 33.4775 35.1523 33.4775C34.1613 32.5766 32.846 32.0181 31.4046 32.0181C29.4586 32.0181 27.7649 33.0091 26.7739 34.5226C26.9001 34.5226 27.0262 34.5226 27.1343 34.5226C27.7109 34.5226 28.6118 34.4505 28.6118 34.4505C28.9181 34.4505 28.9541 34.8649 28.6478 34.9009C28.6478 34.9009 28.3415 34.937 28.0172 34.955L30.0352 40.973L31.2424 37.3334L30.3775 34.973C30.0712 34.973 29.801 34.919 29.801 34.919C29.4946 34.919 29.5307 34.4505 29.837 34.4685C29.837 34.4685 30.7559 34.5406 31.2964 34.5406C31.873 34.5406 32.7739 34.4685 32.7739 34.4685C33.0802 34.4685 33.1163 34.8829 32.81 34.919C32.81 34.919 32.5037 34.955 32.1793 34.973L34.1793 40.937L34.7379 39.0811C34.9721 38.3064 35.1523 37.7658 35.1523 37.2793L35.1343 37.2973Z"
                fill="#797997"
              />
              <path
                d="M31.4765 38.0542L29.8188 42.883C30.3234 43.0272 30.8459 43.1173 31.3864 43.1173C32.0351 43.1173 32.6477 43.0092 33.2243 42.811C33.2243 42.7929 33.1882 42.7569 33.1882 42.7389L31.4765 38.0722V38.0542Z"
                fill="#797997"
              />
              <path
                d="M36.2513 34.9009C36.2693 35.0811 36.2873 35.2612 36.2873 35.4775C36.2873 36.036 36.1792 36.6666 35.8729 37.4594L34.1792 42.3603C35.8369 41.4054 36.936 39.6036 36.936 37.5675C36.936 36.5946 36.6837 35.6937 36.2513 34.9009Z"
                fill="#797997"
              />
              <path
                d="M31.3864 31.0991C27.8189 31.0991 24.918 34 24.918 37.5676C24.918 41.1352 27.8189 44.0361 31.3864 44.0361C34.954 44.0361 37.8549 41.1352 37.8549 37.5676C37.8549 34 34.954 31.0991 31.3864 31.0991ZM31.3864 43.7478C27.981 43.7478 25.2063 40.973 25.2063 37.5676C25.2063 34.1622 27.981 31.3874 31.3864 31.3874C34.7918 31.3874 37.5666 34.1622 37.5666 37.5676C37.5666 40.973 34.7918 43.7478 31.3864 43.7478Z"
                fill="#797997"
              />
              <path
                d="M0.449463 38.6306H6.21523"
                stroke="#797997"
                stroke-width="1.34838"
                stroke-miterlimit="10"
                stroke-linecap="round"
              />
              <path
                d="M3.33228 41.5315V35.7477"
                stroke="#797997"
                stroke-width="1.34838"
                stroke-miterlimit="10"
                stroke-linecap="round"
              />
              <path
                d="M56.7737 11.5676H53.7827"
                stroke="#B0B0D3"
                stroke-width="0.898921"
                stroke-miterlimit="10"
                stroke-linecap="round"
              />
              <path
                d="M55.2783 13.1532V10"
                stroke="#B0B0D3"
                stroke-width="0.898921"
                stroke-miterlimit="10"
                stroke-linecap="round"
              />
              <path
                d="M61.7468 20.3784C62.6125 20.3784 63.3143 19.6443 63.3143 18.7388C63.3143 17.8332 62.6125 17.0991 61.7468 17.0991C60.881 17.0991 60.1792 17.8332 60.1792 18.7388C60.1792 19.6443 60.881 20.3784 61.7468 20.3784Z"
                stroke="#797997"
                stroke-width="0.898921"
                stroke-miterlimit="10"
                stroke-linecap="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_6309_21177">
                <rect width="64" height="64" fill="white" />
              </clipPath>
            </defs>
          </svg>

          <h5 class="my-4">{{ $t('message.emptyTable.instances.title') }}</h5>
          <p class="mb-0">
            {{ $t('message.emptyTable.instances.subtitle') }}
          </p>
          <v-btn
            color="primary"
            elevation="0"
            class="mt-7"
            @click="loadServices"
          >
            <v-icon size="16" class="mr-2">$plus</v-icon>
            {{ $t('button.instance.addNew') }}
          </v-btn>
        </div>
      </template>
      <template v-else>
        <div v-for="item in 5" :key="item" class="d-flex py-2 px-6">
          <v-skeleton-loader type="avatar" class="my-1" />

          <div class="d-flex flex-column ml-4">
            <v-skeleton-loader
              type="text"
              height="16"
              width="140"
              class="mb-1"
            />
            <v-skeleton-loader type="text" height="14" width="80" />
          </div>
        </div>
      </template>
    </v-card-text>

    <v-divider />

    <div
      class=""
      v-if="
        instances.length &&
        instances.length !== totalInstances &&
        totalInstances > 5
      "
    >
      <v-btn
        :loading="loadingServices"
        x-large
        block
        text
        color="primary"
        class="load-more-btn"
        @click="reloadInstances(99)"
      >
        <span class="p-4 font-weight-regular black--text">{{ $t('button.loadMore') }}</span>
      </v-btn>
    </div>
  </v-card>
</template>

<script>
import Api from "@/apis/Api";

import InstanceAvatar from "../InstanceAvatar.vue";
import StatusIndicator from "../instances/StatusIndicator.vue";
import SharesLabel from "../labels/SharesLabel.vue";

export default {
  components: {
    InstanceAvatar,
    StatusIndicator,
    SharesLabel,
  },
  props: {
    service: Object
  },
  data: function () {
    return {
      loading: true,
      instances: [],
      totalInstances: 0,
      loadingServices: false,
    };
  },
  watch: {
    service: function () {
      this.$nextTick(() => {
        this.reloadInstances();
      });
    },
  },
  methods: {
    loadServices() {
      this.loadingServices = true;
      Api.services().then(() => {
        this.loadingServices = false;
        this.startInstallationProcess();
      });
    },
    startInstallationProcess: function () {
      this.$store.dispatch("startInstallationProcess");
    },
    reloadInstances: async function (number = 5) {
      this.loading = true;

      if (!this.service) {
        this.loading = false;
        return;
      }

      const results = await Promise.all(this.service.server_accounts.map(async (serverAccount) => {
        let { data, meta, error } = await Api.instances().list(
          1,
          number,
          "created_at",
          true,
          "",
          [],
          serverAccount.id
        );

        if (error || !data) {
          return { error, data: null };
        }

        return { data, meta };
      }));


      this.instances = [];
      this.totalInstances = 0;

      for (const result of results) {
        if (result.error) {
          continue;
        }
        this.instances.push(...result.data);
        this.totalInstances += result.meta.total;
      }
      this.$emit('instances-loaded', this.instances);
      this.loading = false;
    },
    loadAllInstances: async function () {},
  },
  mounted: async function () {
    await this.reloadInstances();
  },
};
</script>

<style lang="scss" scoped>
a {
  color: var(--v-gray-darken1);
}

.load-more-btn::v-deep {
  background: transparent;
  &:before {
    background: transparent !important;
  }
  .v-btn__content {
    > span {
      transition: color 0.24s ease;
    }
  }
  &:hover {
    .v-btn__content {
      > span {
        color: var(--v-primary-base) !important;
      }
    }
  }
}

.v-btn {
  &.v-size--x-large {
    height: 50px;
    font-size: $font-size-xs;
    line-height: $line-height-xs;
    font-weight: $font-weight-normal;
  }
}

.instance {
  transition: background-color 0.3s ease;
  max-width: 100%;
  &__title {
    max-width: calc(100% - 80px);
  }
  h6 {
    max-width: 100%;
    text-overflow: ellipsis;
  }
  &:hover {
    background-color: var(--v-primary-lighten4);
  }
  &__title,
  &__link {
    transition: color 0.3s ease;
  }
  &__title {
    &:hover {
      color: var(--v-primary-darken1);
    }
  }
  &__link {
    &:hover {
      color: var(--v-info-base);
    }
  }
}
.v-skeleton-loader::v-deep {
  .v-skeleton-loader__avatar {
    width: 40px;
    height: 40px;
  }
}

.no-data {
  padding: 32px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  h5 {
    color: var(--v-gray-darken2);
    font-size: $font-size-lg;
    line-height: $line-height-lg;
    font-weight: $font-weight-bold;
  }
  p {
    max-width: 280px;
    color: var(--v-gray-darken1);
    font-size: $font-size-sm;
    line-height: $line-height-sm;
    text-align: center;
    font-weight: $font-weight-normal;
  }
}

.suspend-chip {
  border-radius: 5px;
  background-color: var(--v-sand-lighten5);
  color: var(--v-sand-darken2);
  font-weight: $font-weight-bold;
  font-size: $font-size-xxs;
  line-height: $line-height-xxs;
  padding: 4px;
}
</style>
